@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

.App {
  overflow-x: hidden;
}

.animation-scroll {
  scroll-behavior: smooth !important;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.page-active {
  background-color: rgb(219, 219, 219);
  padding: 5px 12px;
  border-radius: 100%;
}

select {
  background-color: transparent;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  margin: auto;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c7c7c7;
  opacity: 1; /* Firefox */
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="date"]::before {
	color: #999999;
	content: attr(placeholder);
  text-align: center;
}
input[type="date"] {
	color: #ffffff;
}
input[type="date"]:focus,
input[type="date"]:valid {
	color: #666666;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
	content: "" !important;
} */

/* Scroll styles start */

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  /* background: #91959E; */
  background: #636771;
}

::-webkit-scrollbar-thumb {
  /* background: #393D45; */
  background: #393D45;
}

::-webkit-scrollbar-thumb:hover {
  /* background: rgba(0, 0, 0, 0.818); */
  background: #393D45;
}

/* Scroll styles end */


/*Inicia --- Scroll únicamente para el modal de Power Window Repair*/
.content-pwr::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

.content-pwr::-webkit-scrollbar-track {
  background: #393D45;
  border-radius: 6px;
}

.content-pwr::-webkit-scrollbar-thumb {
  background: #FF5F52;
  border-radius: 6px;
}

.content-pwr::-webkit-scrollbar-thumb:hover {
  background: #F41A28;
}
/*Termina --- Scroll únicamente para el modal de Power Window Repair*/


/* Dropdown styles start */

.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #939191;
  text-align: center;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: #FF5F52 2px solid;
  border-radius: 0px 0px 15px 15px;
  width: 15vw;
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #323232;
  border-radius: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Dropdown styles end */



/* Loader styles start */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #FF3D00 #FF3D00;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
    
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/* Loader styles end */



/* Datepicker styles start */

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}

.react-datepicker__input-container {
  position: static !important;
}

/* Datepicker styles end */


input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #323232;
  border-radius: 50%;
  background-color: white;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Estilo cuando está marcado */
input[type="checkbox"]:checked {
  background-color: #F41A28;
  box-sizing: border-box;
  border: none;
  /* box-shadow: 0 0 0 2px white, 0 0 0 4px #F41A28; */
  border: 3px solid white;
  outline: 1px solid #F41A28;

}

.webkit-center {
  text-align: -webkit-center;
}

